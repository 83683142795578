import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, TemplateRef, AfterViewInit } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@jsverse/transloco';

// export class SelectVirtualScrollErrorStateMatcher implements ErrorStateMatcher {
// 	required = false;
// 	isErrorState(
// 		control: FormControl | null,
// 		// form: FormGroupDirective | NgForm | null
// 	): boolean {
// 		if (this.required && control && control.untouched && control.invalid) {
// 			return true;
// 		}
// 	}
// }


@Component({
	selector: 'select-virtual-scroll',
	templateUrl: './select-virtual-scroll.component.html',
	styleUrls: ['./select-virtual-scroll.component.scss'],
})
export class SelectVirtualScrollComponent implements OnInit, AfterViewInit {

	static multipleOldSelectedIds: {
		[key: string]: object;
	} = {};

	loading = true;
	loadingAddNew = false;
	loadingSearchItems = false;
	attrMatSelectChip = false;
	searchKeyword = {
		keyword: '',
		keywordNormalize: '',
		timer: null,
		minLength: 3
	};
	addNewModel = {
		description: ''
	};
	editItemModel: {
		item: any,
		model: any
	};
	readonly defaultAddNewIptMaxLength = 50;
	searchResult = [];
	selected = {};
	opened = null;
	showAddNewButton = false;
	addingNew = false;
	editingItem = false;
	deletingItem = false;

	// errorStateMatcher = new SelectVirtualScrollErrorStateMatcher();
	// formControl = new FormControl(null);

	private multipleCopyModel = [];

	// @Input() loading: boolean;
	@Input() required = false;
	@Input() multiple = false;
	_selectVistualScrollFormControl: UntypedFormControl;
	@Input() set selectVistualScrollFormControl(control: AbstractControl<any, any>){
		this._selectVistualScrollFormControl = control as UntypedFormControl;
	};
	get selectVistualScrollFormControl(): UntypedFormControl{
		return this._selectVistualScrollFormControl;
	}
	@Input() model: any;
	@Input() label: string;
	@Input() key: string;
	@Input() data: Array<any>;
	@Input() dataProperties: SelectVirtualScrollDataProperties<any>;
	@Input() disabled = false;
	@Input() autoSelectWhenOnlyOneOption = false;
	@Input() panelNoMaxWidth = false;
	@Input() defaultMargins = false;
	@Input() attrMatSelectResetButton = false;
	@Input() optionTemplate: TemplateRef<any>;
	@Input() triggerTemplate: TemplateRef<any>;
	@Input() addNewItem: SelectVirtualScrollAddNewItem;
	@Input() editItem: SelectVirtualScrollEditItem;
	@Input() searchItem: SelectVirtualScrollSearchItem;
	@Input() scrollItemSize = 48;
	@Input() panelClass = '';
	@Output() itemSelected = new EventEmitter<any>();

	@Input() set matSelectChip(MatSelectChipValue: string){
		if(typeof MatSelectChipValue !== 'undefined'){
			this.attrMatSelectChip = true;
		}
	};

	@Input() set isReady(isReady: boolean){
		if(isReady){
			setTimeout(() => {this.ready();});
		}else{
			setTimeout(() => {this.notReady();});
		}
	};


	@ViewChild(CdkVirtualScrollViewport, { static: false })
		cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

	@ViewChild('optionWorkaround', { static: false })
		optionWorkaround: MatOption;


	@ViewChild('matSelect', { static: false })
		matSelect: MatSelect;

	@ViewChild('inputSearch') inputSearch: ElementRef;
	@ViewChild('inputNewItem') set inputNewItem(inputNewItem: ElementRef){
		if(inputNewItem?.nativeElement){
			setTimeout(() => inputNewItem.nativeElement.focus());
		}
	}

	@ViewChild('wrapperVirtualScroll', {read: ElementRef}) wrapperVirtualScroll: ElementRef;

	constructor(
		private matSnackBar: MatSnackBar,
		private translocoService: TranslocoService
	){
	}

	ngOnInit(): void {
		this.setShowAddNewItemButton();
		this.setFormControl();
		// if(this.required){
		// 	this.errorStateMatcher.required = true;
		// 	this.formControl.setValidators([
		// 		Validators.required
		// 	]);
		// }
		// if(!this.model[this.key]){
		// 	this.model[this.key] = '';
		// }
		// console.log('matSelectChip', this.matSelectChip);

	}

	setShowAddNewItemButton(){
		if(this.addNewItem){
			if(!this.addNewItem.showNewButtonWhen){
				this.showAddNewButton = true;
			}else{
				this.showAddNewButton = this.addNewItem.showNewButtonWhen(
					this.searchKeyword.keyword,
					this.data
				)
			}
		}
	}

	setFormControl(){
		if(this.selectVistualScrollFormControl){

			if(this.multiple && !Array.isArray(this.selectVistualScrollFormControl.value)){
				this.selectVistualScrollFormControl.setValue([]);
			}

			this.key = 'formControl';

			this.model = {
				[this.key]: this.selectVistualScrollFormControl.value
			};

			this.selectVistualScrollFormControl.registerOnChange(value => {
				this.model[this.key] = value;
				this.delegateSelectFromModel();
			});

		}
	}

	isDisabled(){
		if(this.selectVistualScrollFormControl){
			return this.selectVistualScrollFormControl.disabled;
		}else{
			return this.disabled;
		}
	}

	isInvalid(){
		if(this.selectVistualScrollFormControl){
			return this.selectVistualScrollFormControl.touched && this.selectVistualScrollFormControl.invalid;
		}else{
			return !this.loading && !this.isDisabled() && this.required && !this.hasValue();
		}
	}

	ngAfterViewInit(): void {
		this.preventKeySpace();
	}

	ready(){
		this.autoSelectWhenOnlyOneOptionAvailable();
		this.delegateSelectFromModel();
		this.loading = false;
	}

	notReady(){
		this.delegateSelectFromModel();
		this.loading = true;
	}

	autoSelectWhenOnlyOneOptionAvailable(){
		if(this.autoSelectWhenOnlyOneOption && this.data.length === 1){
			if(this.model[this.key] !== this.data[0][this.dataProperties.id]){
				this.model[this.key] = this.data[0][this.dataProperties.id];
				this.selectItem(this.data[0]);
			}
		}
	}

	private preventKeySpace(){
		this.matSelect._handleKeydown = (event: KeyboardEvent) => {

			if(event.code === 'Escape'){
				this.matSelect.focus();
			}

			if(event.code === 'Enter'){
				this.matSelect.open();
			}

			if(event.code === 'Space'){
				this.matSelect.open();
				return;
			}

			// if(!this.matSelect.disabled){
			// 	this.matSelect.panelOpen
			// 		? this.matSelect._handleOpenKeydown(event)
			// 		: this.matSelect._handleClosedKeydown(event);
			// }

		};
	}


	private setSearchResult(keyword: string = ''){

		keyword = keyword.trim();

		if(this.getSearchItem(keyword)){
			return;
		}

		const keywordNormalize =
			keyword
				.toLowerCase()
				.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

		this.searchKeyword.keywordNormalize = keywordNormalize;

		const regexp = new RegExp(
			keywordNormalize.replace(/([\\*()\u005B\u005D{}.?^$])/g, '\\$1'),
			'i'
		);

		this.searchResult = this.data.filter(item => regexp.test(
			`${item[this.dataProperties.descExtra1]}${item[this.dataProperties.desc]}`
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
		));

	}

	private getSearchItemLastSearchKeyword = '';
	private getSearchItemSubscription: Subscription;
	private getSearchItem(keyword: string = ''): boolean{

		if(!this.searchItem){
			return false;
		}

		if(this.getSearchItemLastSearchKeyword === keyword){
			return true;
		}

		if(!keyword || keyword.length < this.searchKeyword.minLength){
			if(!this.selected?.[this.dataProperties.id]){
				this.loadingSearchItems = true;
				this.getSearchItemFinalize();
			}
		}else{
			if(this.getSearchItemSubscription){
				this.getSearchItemSubscription.unsubscribe();
			}
			this.loadingSearchItems = true;
			this.getSearchItemSubscription = this.searchItem.service(keyword)
				.subscribe({
					next: data => this.getSearchItemFinalize(data),
					error: () => this.getSearchItemFinalize()
				});
		}

		this.getSearchItemLastSearchKeyword = keyword;
		return true;

	}

	private getSearchItemFinalize(data: any[] = null){
		this.data.splice(0);
		setTimeout(() => this.loadingSearchItems = false);
		if(data){
			this.data.push.apply(this.data, data);
		}
		this.setShowAddNewItemButton();
	}

	search($event: KeyboardEvent, value){
		clearTimeout(this.searchKeyword.timer);
		this.searchKeyword.timer = setTimeout(() =>
			this.setSearchResult(value),
		300
		);
	}

	inputSearchOnModelChange(keyword: string){
		if(this.addNewItem?.forceUppercase){
			this.searchKeyword.keyword = keyword.toUpperCase();
		}
	}

	// iptAddNewKeyup($event: KeyboardEvent){
	// 	if($event.key === 'Enter' && !this.loadingAddNew){
	// 		this.submitNewItem();
	// 	}
	// }

	openedChangeWhenSearchItem(status: boolean){
		if(this.searchItem){
			this.getSearchItemLastSearchKeyword = '';
			this.searchResult = this.data = [];
			if(status && this.selected?.[this.dataProperties.id]){
				this.data.push(this.selected);
			}
		}
	}

	openedChangeSearchKeywordNormalize(){
		this.searchKeyword.keyword = '';
		this.searchKeyword.keywordNormalize = '';

		if(this.inputSearch?.nativeElement){
			this.inputSearch.nativeElement.focus();
		}

		this.searchResult = this.data;
	}

	openedChange(status){

		this.setModeToSelect();
		this.openedChangeWhenSearchItem(status);
		this.openedChangeSearchKeywordNormalize();

		if(!status && this.selectVistualScrollFormControl){
			this.selectVistualScrollFormControl.markAsTouched();
		}

		this.opened = status;

		if(status && this.multiple){
			this.multipleCopyModel.splice(0);
			Object.assign(
				this.multipleCopyModel,
				this.model[this.key]
			);
		}

		if(this.cdkVirtualScrollViewPort){
			this.cdkVirtualScrollViewPort.checkViewportSize();
			if(this.selected && !this.multiple){
				const selectedIndex = this.data.findIndex(item => item === this.selected);
				setTimeout(() => {
					this.cdkVirtualScrollViewPort.scrollToIndex(selectedIndex);
					// const element = this.wrapperVirtualScroll.nativeElement as Element;
					// const optionSelected = element.querySelector('mat-option.virtual-scroll-item-selected') as any;
					// if(optionSelected && optionSelected.focus){
					// 	optionSelected.focus();
					// }
				})
			}
		}

		this.delegateSelectFromModel();
		this.setShowAddNewItemButton();

	}

	private setSelected(item){
		this.selected = item;
	}

	selectItemKeydown($event: KeyboardEvent, item){
		if(/^(Enter)/.test($event.code)){
			this.selectItem(item);
		}
		this.wrapperVirtualScrollOnKeyLetters($event);
	}

	private keydownTimer;
	private keydownRelease = true;
	wrapperVirtualScrollOnKeyDown(key: KeyboardEvent){
		key.stopPropagation();
		if(/ArrowDown|ArrowUp|Tab/.test(key.code)){
			key.preventDefault();
			if(this.keydownRelease){
				clearTimeout(this.keydownTimer);
				this.keydownTimer = null;
				this.wrapperVirtualScrollOnKeyArrows(key);
			}
			if(!this.keydownTimer){
				this.keydownTimer = setTimeout(
					() => this.keydownRelease = true,
					100
				)
			}
			this.keydownRelease = false;
		}else if (key.code === 'Escape'){
			this.matSelect.close();
			this.matSelect.focus();
		}
	}

	wrapperVirtualScrollOnKeyLetters(key: KeyboardEvent){

		if(/ArrowDown|ArrowUp/.test(key.code)){
			return;
		}

		let doSearch = false;
		const keyLetter = key.code.match(/(Key|Digit)([a-z0-9])/i)?.[2];

		if(keyLetter && key.target !== this.inputSearch.nativeElement){
			// workaround for keyup on viewport
			// this.searchKeyword.keyword += (keyLetter || '').toLowerCase();
			this.inputSearch.nativeElement.focus();
			doSearch = true;
		}

		if(/^(Backspace|Space)/.test(key.code) && this.searchKeyword.keyword){
			// workaround for keyup on viewport
			// this.searchKeyword.keyword = this.searchKeyword.keyword.replace(/.$/,'');
			this.inputSearch.nativeElement.focus();
			doSearch = true;
		}

		if(doSearch){
			this.search(key, this.searchKeyword.keyword);
		}

	}

	wrapperVirtualScrollOnKeyArrows(key: KeyboardEvent){

		if(!/ArrowDown|ArrowUp|Tab/.test(key.code)){
			return;
		}

		const element = (this.wrapperVirtualScroll.nativeElement as Element);
		let option;

		let optionFocused = element.querySelector('mat-option:focus-visible');

		if(!optionFocused){
			optionFocused = element.querySelector('mat-option.virtual-scroll-item-selected');
		}

		const options = element.querySelectorAll('mat-option')
		options.forEach(
			(elementOption, index) => {
				if(elementOption === optionFocused){
					if(key.code === 'ArrowDown' || key.code === 'Tab' && !key.shiftKey){
						option = options[index < options.length-1 ? index+1 : index];
					}else if(key.code === 'ArrowUp' || key.code === 'Tab' && key.shiftKey){
						option = options[index > 0 ? index-1 : 0];
					}
				}
			}
		);

		if(!option){
			option = element.querySelector('mat-option') as any;
		}

		if(option){
			option.focus();
		}

	}

	selectItem(item){

		if(this.multiple){

			const model = this.model[this.key];
			const itemId = item[this.dataProperties.id];
			const indexOfItemId = this.multipleCopyModel.indexOf(itemId);

			if(indexOfItemId >= 0){
				this.multipleCopyModel.splice(indexOfItemId, 1);
			}else{
				this.multipleCopyModel.push(itemId);
			}

			model.splice(0);
			model.push.apply(model, this.multipleCopyModel);

		}

		this.setSelected(item);

		if(this.selectVistualScrollFormControl){
			this.selectVistualScrollFormControl.setValue(this.model[this.key]);
			this.selectVistualScrollFormControl.markAsTouched();
		}

		this.itemSelected.emit(item);

	}

	getMatOptionClass(item){
		return {
			'virtual-scroll-item-selected': item === this.selected,
			'mat-mdc-option-active': false
		};
	}

	delegateSelectFromModel(){

		const value = this.model[this.key];

		this.selectFromModelMultiple(value);
		this.selectFromModelSingle(value);

	}

	private selectFromModelSingle(value){

		let itemFilter;

		if(!this.multiple){

			if(value && this.data?.length){
				[itemFilter] = this.data.filter(
					item => item[this.dataProperties.id] === value
				);
			}

			if(itemFilter){
				this.setSelected(itemFilter);
				this.optionWorkaround.value = value;
				this.optionWorkaround.select();
			}else if(!value){
				this.selected = {};
			}

			if(this.opened === null && itemFilter){
				this.itemSelected.emit(itemFilter);
			}

		}

	}

	private selectFromModelMultiple(value){
		if(this.multiple){

			this.setMultipleOldSelectedIds();

			if(value.length && SelectVirtualScrollComponent.multipleOldSelectedIds[this.key][value[0]]){
				this.setSelected(SelectVirtualScrollComponent.multipleOldSelectedIds[this.key][value[0]]);
			}else{
				this.setSelected({});
			}

			return;

		}
	}

	setMultipleOldSelectedIds(){

		const value = this.model[this.key];

		if(!SelectVirtualScrollComponent.multipleOldSelectedIds[this.key]){
			SelectVirtualScrollComponent.multipleOldSelectedIds[this.key] = {};
		}

		this.data.map(item => {
			const id = item[this.dataProperties.id];
			if(value.indexOf(id) >= 0){
				SelectVirtualScrollComponent.multipleOldSelectedIds[this.key][id] = item;
			}
		});

	}

	reset($event: MouseEvent){

		$event.stopPropagation();

		if(this.multiple){
			this.model[this.key].splice(0);
		}else{
			this.model[this.key] = null;
		}

		if(this.selectVistualScrollFormControl){
			this.selectVistualScrollFormControl.markAsTouched();
			this.selectVistualScrollFormControl.setValue(this.model[this.key]);
		}

		this.delegateSelectFromModel();
		this.itemSelected.emit(null);

	}

	hasValue(){
		if(this.multiple){
			return this.model[this.key] && this.model[this.key].length;
		}else{
			return !!this.model[this.key];
		}
	}

	setModeToAddNew(){

		if(!this.searchKeyword.keyword){
			return;
		}

		if(this.addNewItem.customButtonAction){
			this.addNewItem.customButtonAction().subscribe(
				item => {
					this.pushNewItemToDataArray(item);
					this.submitNewItemSuccess(item);
				}
			);
			this.matSelect.close();
			return;
		}

		this.addNewModel.description = (this.searchKeyword.keyword || '')
			.replace(new RegExp(
				'^(.{' + (this.addNewItem?.inputOptions?.maxLength || this.defaultAddNewIptMaxLength) + '}).+'
			),'$1');
		this.searchKeyword.keyword = '';
		this.search(null, '');

		if(this.addNewItem.skipPrompt){
			this.submitNewItem();
			return;
		}

		this.addingNew = true;
		this.editingItem = false;


	}

	setModeToEditItem($event: MouseEvent, item: any){
		$event.stopPropagation();

		if(this.editItem.customButtonAction){
			this.editItem.customButtonAction(item).subscribe(
				itemUpdated => {
					Object.assign(item, itemUpdated);
					if(this.editItem.deleteKey && item[this.editItem.deleteKey] === 0){
						this.submitDeleteEditItem(item);
						this.setSearchResult();
					}
				}
			);
			this.matSelect.close();
			return;
		}

		this.editingItem = true;
		this.addingNew = false;
		this.editItemModel = {
			item,
			model: Object.assign(
				{},
				item
			)
		};
	}

	setModeToSelect(){
		this.addingNew = false;
		this.editingItem = false;
		this.deletingItem = false;
	}

	setModeDeleteItem(){
		this.deletingItem = true;
	}

	submitNewItem(){

		if(this.itemAlreadyExists(this.addNewModel.description)){
			return;
		}

		this.loadingAddNew = true;

		this.addNewItem.service(this.addNewModel.description).subscribe({
			next: result => {

				if(!result){
					this.loadingAddNew = false;
					return;
				}

				this.pushNewItemToDataArray(result);

				this.submitNewItemSuccess(result);

			},
			error: result => {
				this.loadingAddNew = false;
			}
		});

	}

	pushNewItemToDataArray(item: any){
		this.data.unshift(item);
	}

	submitNewItemSuccess(item: any){

		this.search(null, '');

		if(Array.isArray(this.model[this.key])){
			this.model[this.key].push(item[this.dataProperties.id]);
		}else{
			this.model[this.key] = item[this.dataProperties.id];
		}

		this.selectItem(item);

		this.matSelect.close();

		this.loadingAddNew = false;

		this.matSnackBar.open(
			this.translocoService.translate(
				'DefaultHttpRequestMessages.AddSuccessLabel',
				{
					label: this.label
				}
			),
			null,
			{
				panelClass: 'success',
				duration: 3000
			}
		);

	}

	itemAlreadyExists(description: string): boolean{

		const itemFound = this.data.find(
			item => item[this.dataProperties.desc] === description
		);

		if(itemFound){
			if(this.addingNew){
				this.submitNewItemSuccess(itemFound);
			}else if(this.editingItem){
				this.matSnackBar.open(
					this.translocoService.translate(
						'Components.SelectVirtualScroll.EditDescExists',
						{
							label: this.label
						}
					),
					null,
					{
						panelClass: 'warn',
						duration: 3000
					}
				);
			}
		}

		return !!itemFound;

	}

	submitEditItem(){

		if(
			!this.deletingItem
			&&
			this.itemAlreadyExists(this.editItemModel.model[this.dataProperties.desc])
		){
			return;
		}

		this.loadingAddNew = true;

		if(this.deletingItem) {
			this.editItemModel.model[this.editItem.deleteKey] = 0;
		}

		this.editItem.service(this.editItemModel.model).subscribe({
			next: result => {

				this.loadingAddNew = false;

				if(!result){
					return;
				}

				const index = this.data.indexOf(this.editItemModel.item);

				if(this.model[this.key] === this.editItemModel.item[this.dataProperties.id]){
					setTimeout(()=>{
						this.selectItem(this.editItemModel.item);
					});
				}

				if(this.deletingItem){
					this.submitDeleteEditItem(this.editItemModel.item);
				}else{
					this.data.splice(index, 1, this.editItemModel.model);
				}

				this.setModeToSelect();

			},
			error: result => {
				this.loadingAddNew = false;
			}
		});

	}

	submitDeleteEditItem(item: any){
		const index = this.data.indexOf(item);
		this.data.splice(index, 1);
		this.deleteItemSelected(item);
	}

	deleteItemSelected(itemDeleted: any){
		if(
			this.selectVistualScrollFormControl
			&&
			this.selectVistualScrollFormControl.value === itemDeleted[this.dataProperties.id]
		){
			this.selectVistualScrollFormControl.setValue(null);
		}else if(this.multiple && this.multipleCopyModel.indexOf(itemDeleted[this.dataProperties.id]) >= 0){
			this.selectItem(itemDeleted);
		}

	}

}


export interface SelectVirtualScrollDataProperties<T = any> {
	[key: string]: any;
	id: keyof T | 'compositeId';
	desc: keyof T;
}

export interface SelectVirtualScrollAddNewItem<T = any> {
	buttonLabel?: string;
	skipPrompt?: boolean;
	forceUppercase?: boolean;
	service?: (description: string) => Observable<T>;
	showNewButtonWhen?: (searchKeyword: string, data: T[]) => boolean;
	customButtonAction?: () => EventEmitter<T>;
	inputOptions?: {
		maxLength?: number;
	};
}

export interface SelectVirtualScrollEditItem<T = any> {
	service?: (item: T) => Observable<T>;
	showEditButtonWhen?: (item: T) => boolean;
	customButtonAction?: (item: T) => EventEmitter<T>;
	deleteKey?: keyof T;
	inputOptions?: {
		maxLength?: number;
	};
}

export interface SelectVirtualScrollSearchItem<T = any> {
	service: (search: string) => Observable<T[]>;
	hiddenSearchResult?: number;
	constantFilters?: any;
}
