import { UserPermissions } from 'src/app/auth/auth.interface';
import { APIServiceOrderPlanningGETItemProcessTypes } from './service-order-planning.interface';

export interface AuthTokenAPIGetResponse {
	status: string;
	customer: AuthTokenAPIGetCustomer;
	customer_code: number;
	user_code: number;
	user_name: string;
	user_nick: string;
	translate_code: number;
	language_code: string;
	session_api: string;
	permissions: UserPermissions[];
	customerEnv?: {
		processTypes?: CustomerEnvProcessTypes[]
	}
	[propName: string]: any;
}

export interface CustomerEnvProcessTypes {
	id: string;
	code: APIServiceOrderPlanningGETItemProcessTypes;
	desc: string;
}

export interface AuthTokenAPIGetCustomer {
	customer_code: number;
	customer_name: string;
	timezone: string;
	translate_code: number;
	language_code: string;
	page_size_max: number;
	admin: number;
	keyuser: number;
	logo_url: string;
	logo_api_url: string;
	currency_prefix: string;
	currency_suffix: string;
	parameters: AuthTokenAPIGetCustomerParameters[]
}

export enum AuthTokenAPIGetCustomerParameters {
	CHECKLIST = 'CHECKLIST',
	FEMSA_DASH = 'FEMSA_DASH',
	IO = 'IO',
	MAINTENANCE = 'MAINTENANCE',
	MEASURE = 'MEASURE',
	RPT = 'RPT',
	SCHEDULE_CHECKLIST = 'SCHEDULE_CHECKLIST',
	SERIAL_FATHER = 'SERIAL_FATHER',
	SO = 'SO',
	STAFF = 'STAFF',
	TICKET = 'TICKET',
	TICKET_FROM_CLIENT = 'TICKET_FROM_CLIENT',
	TRANSFER = 'TRANSFER',
	ZAP = 'ZAP',
}
